<template>
	<div>
		<el-dialog :draggable="true" width="40%" @close="Reset" :destroy-on-close="true" v-model="outerVisible"
			title="批量设置办理时间">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="100px">
					<el-form-item label="办理时间">
						<span class="xx">*</span>
						<el-date-picker @change="changeTime" v-model="dates" type="datetimerange" range-separator="-"
							start-placeholder="请选择开始时间" end-placeholder="请选择办理时间">
						</el-date-picker>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="SubmitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/target";
	export default {
		data() {
			return {
				outerVisible: false,
				ruleForm: {
					id: '',
					process_stime: '',
					process_etime: '',
				},
				dates: [],
				listId: []
			}
		},
		methods: {
			// 选择时间
			changeTime(e) {
				if (e) {
					this.ruleForm.process_stime = Math.round(new Date(this.$Moment(e[0]).format('YYYY/MM/DD HH:mm:ss')) /
						1000)
					this.ruleForm.process_etime = Math.round(new Date(this.$Moment(e[1]).format('YYYY/MM/DD HH:mm:ss')) /
						1000)
				} else {
					this.ruleForm.process_stime = ""
					this.ruleForm.process_etime = ""
				}
			},
			Reset() {
				this.dates = []
			},
			//新增
			SubmitForm() {
				this.ruleForm.id = this.listId.join(",")
				if (this.ruleForm.process_stime === '') {
					ElMessage({
						message: '请选择办理时间',
						type: 'warning',
					})
				} else {
					this.$http.post(api.batchProcessTime, this.ruleForm)
						.then((res) => {
							if (res.code == 200) {
								ElMessage({
									type: 'success',
									message: '设置成功',
								})
								this.outerVisible = false
								// 刷新列表
								this.$emit('SubmitForm')
							}
						})
				}
			},

			// 显示隐藏
			show(list) {
				this.listId = list
				this.outerVisible = true
			}
		}
	}
</script>

<style scoped>
</style>