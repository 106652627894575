<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li @click="$router.go(-1)" v-show="$route.query.id">
					<el-button size="mini"><i class="icon iconfont icon-fanhui"></i>返回上一步</el-button>
				</li>
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li v-permission="['targettime']">
					<el-button @click="bintime" class="el-white" size="mini" type="danger">批量设置办理时间</el-button>
				</li>
				<li v-permission="['targetadd']">
					<el-button @click="bindadd('新增')" color="#00ad79" style="color: white" class="el-white"
						size="mini">新增
					</el-button>
				</li>
				<li v-permission="['targetdele']">
					<el-button @click="alldele" class="el-white" size="mini" type="danger">删除</el-button>
				</li>
				<li v-if="$store.state.villageId==0">
					<el-select clearable filterable v-model="form.village_id" placeholder="请选择村">
						<el-option v-for="item in cunlist" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</li>
				<li>
					<el-select clearable v-model="form.status" placeholder="选择状态">
						<el-option label="全部" value=""></el-option>
						<el-option label="待处理" :value="0"></el-option>
						<el-option label="已完成" :value="1"></el-option>
					</el-select>
				</li>
				<li>
					<el-select clearable v-model="form.party_member_id" placeholder="选择党员">
						<el-option v-for="item in dylist" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</li>
				<li>
					<el-button @click="search" color="#00ad79" class="el-white" style="color: white" size="mini">搜索
					</el-button>
				</li>
				<li>
					<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="80" />
				<el-table-column type="index" label="序号" width="80" />
				<el-table-column property="date" label="头像">
					<template #default="scope">
						<el-avatar :size="30" :src="scope.row.pavatar||scope.row.avatar">
							<img src="../../../assets/img/tx.png" />
						</el-avatar>
					</template>
				</el-table-column>
				<el-table-column property="status_txt" label="类型">
					<template #default="scope">
				        <span>{{scope.row.type==0?'后台发布':scope.row.pname + '发布'}}</span>
					</template>
				</el-table-column>
				<el-table-column property="village_name" label="村" />
				<el-table-column property="name" label="名称" />
				<el-table-column property="pname" label="姓名" />
				<el-table-column property="integral" label="积分" />
				<el-table-column property="process_stime" label="开始时间" />
				<el-table-column property="process_etime" label="结束时间" />
				<el-table-column property="status_txt" label="状态" />
				
				<el-table-column property="create_time" label="创建时间" />
				<el-table-column label="操作" width="320">
					<template #default="scope">
						<el-button v-permission="['targetsee']" size="mini"
							@click="bindsee(scope.row.id)">查看信息</el-button>
						<el-button v-permission="['targetedits']" size="mini"
							@click="bindadd('编辑',scope.row.id)">编辑</el-button>
						<el-button v-permission="['targetzeng']" size="mini"
							@click="integral(scope.row.id,'增加积分')">增加积分</el-button>
						<el-button v-permission="['targetshao']" size="mini"
							@click="integral(scope.row.id,'减少积分')">减少积分</el-button>
						<el-button v-permission="['targetdele']" size="mini" @click="dele(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<el-pagination background v-model="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
					:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
		<!-- 新增/编辑弹窗 -->
		<add ref="add" @SubmitForm="getList"></add>
		<!-- 办理时间 -->
		<times ref="time" @SubmitForm="getList"></times>
		<!-- 积分 -->
		<integral ref="integral" @SubmitForm="getList"></integral>
		<!-- 查看 -->
		<see ref="see"></see>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/target";
	import add from './add.vue'
	import see from './see.vue'
	import times from './time.vue'
	import integral from './integral.vue'
	export default {
		components: {
			add,
			times,
			integral,
			see
		},
		data() {
			return {
				maxheight: document.documentElement.clientHeight - 232 + 'px',
				total: 0,
				form: {
					name: '',
					phone: '',
					page: 1,
					limit: 15,
					village_id: '',
					party_member_id: '',
					status: ''
				},
				tableData: [],
				idlist: [],
				cunlist: [],
				dylist: []
			}
		},
		created() {
			if (this.$route.query.id) {
				this.form.party_member_id = Number(this.$route.query.id)
				this.getList()
			}

			this.getList()
			this.getcun()
			this.getdList()
		},
		methods: {
			// 查看
			bindsee(id) {
				this.$refs.see.show(id)
			},
			// 积分
			integral(id, val) {
				this.$refs.integral.show(id, val)
			},
			// 获取党员
			getdList() {
				this.$http.post(api.dlist, {
						page: 1,
						limit: 99999,
						identity: 3
					})
					.then((res) => {
						if (res.code == 200) {
							this.dylist = res.data.data
						}
					})
			},
			// 获取村列表
			getcun() {
				this.$http.post(api.cunlist, {
						page: 1,
						limit: 999,
					})
					.then((res) => {
						if (res.code == 200) {
							this.cunlist = res.data.data
						}
					})
			},
			// 办理时间
			bintime() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要添加时间的数据',
						type: 'warning',
					})
				} else {
					this.$refs.time.show(this.idlist)
				}
			},
			// 新增
			bindadd(val, id) {
				this.$refs.add.show(val, id, this.form.party_member_id)
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 清空
			reset() {
				for (let key in this.form) {
					this.form[key] = ''
				}
				this.form.limit = 15
				this.form.page = 1
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.id)
				})
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.lists, this.form)
					.then((res) => {
						if (res.code == 200) {
							this.tableData = res.data.data
							this.total = res.data.count
							this.tableloading = false
						}
					})
			},
			// 批量删除
			alldele() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.dele, {
									id: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 200) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(id) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.dele, {
								id: id
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>

<style scoped>
</style>