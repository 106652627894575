<template>
	<div>
		<el-dialog :draggable="true" width="85%" top="5vh" v-model="outerVisible" @close="Reset" :title="title">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="排序">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.orderby" placeholder="请输入排序"></el-input>
					</el-form-item>
					<el-form-item label="办理时间">
						<span class="xx">*</span>
						<el-date-picker @change="changeTime" v-model="dates" type="datetimerange" range-separator="-"
							start-placeholder="请选择开始时间" end-placeholder="请选择办理时间">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="党员">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.party_member_id" placeholder="请选择党员">
							<el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="名称">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.name" placeholder="请输入名称"></el-input>
					</el-form-item>
					<el-form-item label="获得积分">
						<span class="xx">*</span>
						<el-input type="number" clearable v-model="ruleForm.integral" placeholder="请输入获得积分"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<!-- 目标内容 -->
			<content ref="content" ctitle="目标内容" @Submitinput="Submitinput">
			</content>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/target";
	import content from '@/components/content.vue'
	export default {
		components: {
			content
		},
		data() {
			return {
				dates: [],
				outerVisible: false,
				title: '新增',
				list: [],
				ruleForm: {
					party_member_id: '',
					name: '',
					process_stime: '',
					process_etime: '',
					integral: '',
					content: '',
					orderby: 0,
				}
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			// 选择时间
			changeTime(e) {
				if (e) {
					this.ruleForm.process_stime = Math.round(new Date(this.$Moment(e[0]).format('YYYY/MM/DD HH:mm:ss')) /
						1000)
					this.ruleForm.process_etime = Math.round(new Date(this.$Moment(e[1]).format('YYYY/MM/DD HH:mm:ss')) /
						1000)
				} else {
					this.ruleForm.process_stime = ""
					this.ruleForm.process_etime = ""
				}
			},
			// 获取党员
			getList() {
				this.$http.post(api.dlist, {
						page: 1,
						limit: 9999,
						identity: 3
					})
					.then((res) => {
						if (res.code == 200) {
							this.list = res.data.data
						}
					})
			},
			// 图文详情
			Submitinput(e) {
				this.ruleForm.content = e
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.info, {
						id: this.ruleForm.id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							this.dates[0] = res.data.process_stime
							this.dates[1] = res.data.process_etime
							delete this.ruleForm.create_time

							// 赋值
							this.$refs.content.setContent(res.data.content)
						}
					})
			},
			// 显示隐藏
			show(val, id,party_member_id) {
				this.getList()
				this.title = val
				if (id) {
					this.ruleForm.id = id
					this.getinfo()
				}
				if(party_member_id&&!id) {
					this.ruleForm.party_member_id = party_member_id
				}
				this.outerVisible = !this.outerVisible
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
				this.dates = []
				this.$refs.content.emptyContent()
				delete this.ruleForm.id
				delete this.ruleForm.delete_time
			},
			//新增/编辑
			submitForm() {
				if (this.ruleForm.process_stime === '') {
					ElMessage({
						message: '请选择办理时间',
						type: 'warning',
					})
				} else if (this.ruleForm.party_member_id === '') {
					ElMessage({
						message: '请选择党员',
						type: 'warning',
					})
				} else if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入名称',
						type: 'warning',
					})
				} else if (this.ruleForm.integral === '') {
					ElMessage({
						message: '请输入获得积分',
						type: 'warning',
					})
				} else if (this.ruleForm.content === '') {
					ElMessage({
						message: '请输入目标内容',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}
				}
			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$emit('SubmitForm')
							this.outerVisible = false
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			}
		}
	}
</script>

<style scoped>
</style>