<template>
	<div>
		<el-dialog :draggable="true" width="85%" top="5vh" v-model="outerVisible" @close="Reset" title="查看信息">
			<div class="dialog no-bgs">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="办理时间">
						<span class="xx"> </span>
						<el-date-picker disabled @change="changeTime" v-model="dates" type="datetimerange"
							range-separator="-" start-placeholder="请选择开始时间" end-placeholder="请选择办理时间">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="党员">
						<span class="xx"> </span>
						<el-select disabled clearable v-model="ruleForm.party_member_id" placeholder="">
							<el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="名称">
						<span class="xx"> </span>
						<el-input disabled clearable v-model="ruleForm.name" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="获得积分">
						<span class="xx"> </span>
						<el-input disabled clearable v-model="ruleForm.integral" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="完成描述">
						<span class="xx"> </span>
						<el-input type="textarea" disabled clearable v-model="ruleForm.info"
							placeholder=""></el-input>
					</el-form-item>
					<div class="title">完成上传的图片</div>
					<div class="img-list" v-if="ImgList.length>0">
						<div v-for="(item,index) in ImgList" :key="index">
							<el-image class="dialog-img" :src="item" :preview-src-list="ImgList" :initial-index="index"
								fit="fill">
							</el-image>
						</div>
					</div>
					<div v-if="ImgList.length===0"
						style="height: 200px; display: flex; width: 100%; align-items: center; justify-content: center; text-align: center;">
						<el-empty description="暂无图片信息" ></el-empty>
					</div>
				</el-form>
			</div>
			<!-- 目标内容 -->
			<h2>
				目标内容
			</h2>
			<div class="content" v-html="ruleForm.content"></div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/target";
	export default {
		data() {
			return {
				dates: [],
				outerVisible: false,
				list: [],
				ruleForm: {
					party_member_id: '',
					name: '',
					process_stime: '',
					process_etime: '',
					integral: '',
					content: '',
					orderby: 0,
					id: ''
				},
				ImgList: []
			}
		},
		methods: {
			// 选择时间
			changeTime(e) {
				if (e) {
					this.ruleForm.process_stime = Math.round(new Date(this.$Moment(e[0]).format('YYYY/MM/DD HH:mm:ss')) /
						1000)
					this.ruleForm.process_etime = Math.round(new Date(this.$Moment(e[1]).format('YYYY/MM/DD HH:mm:ss')) /
						1000)
				} else {
					this.ruleForm.process_stime = ""
					this.ruleForm.process_etime = ""
				}
			},
			// 获取党员
			getList() {
				this.$http.post(api.dlist, {
						page: 1,
						limit: 9999,
						identity: 3
					})
					.then((res) => {
						if (res.code == 200) {
							this.list = res.data.data
						}
					})
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.info, {
						id: this.ruleForm.id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							this.dates[0] = res.data.process_stime
							this.dates[1] = res.data.process_etime
							this.ImgList = res.data.imgpath? res.data.imgpath.split(","):[]
						}
					})
			},
			// 显示隐藏
			show(id) {
				this.ruleForm.id = id
				this.getinfo()
				this.getList()
				this.outerVisible = !this.outerVisible
			}
		}
	}
</script>

<style scoped lang="scss">
	.content {
		width: 100%;
		height: 500px;
		margin-top: 20px;
		padding: 15px;
		overflow-y: auto;
		border: 1px solid #ebeef5;
	}

	.title {
		font-size: 16px;
		padding-bottom: 20px;
	}
	.img-list {
		width: 100%;
		padding: 30px;
		min-height: 300px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		.dialog-img{
			margin-bottom: 15px;
		}
	}
</style>