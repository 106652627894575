<template>
	<div>
		<el-dialog :draggable="true" width="40%" @close="Reset" :destroy-on-close="true" v-model="outerVisible"
			:title="title">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="100px">
					<el-form-item :label="title">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.integral" :placeholder="'请输入' + title"></el-input>
					</el-form-item>

				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="SubmitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/target";
	export default {
		data() {
			return {
				title: '',
				outerVisible: false,
				ruleForm: {
					integral: "",
					id: ''
				},
				title: ''

			}
		},
		methods: {
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
			},
			//新增/编辑
			SubmitForm() {
				if (this.ruleForm.integral === '') {
					ElMessage({
						message: '请输入增加积分',
						type: 'warning',
					})
				} else {
					if (this.title == '增加积分') {
						this.$http.post(api.incPoints, this.ruleForm)
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '增加成功',
									})
									this.$emit('SubmitForm')
									this.outerVisible = false
								}
							})
					} else {
						this.$http.post(api.decPoints, this.ruleForm)
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '减少成功',
									})
									this.$emit('SubmitForm')
									this.outerVisible = false
								}
							})
					}

				}
			},

			// 显示隐藏
			show(id, val) {
				this.title = val
				this.ruleForm.id = id
				this.outerVisible = true
			}
		}
	}
</script>

<style scoped>
</style>